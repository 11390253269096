/**
 * Get the event id the customer is currently part of.
 *
 * This is made available by an inline script included in the frame when the
 * page is rendered.
 *
 * @returns {(Number | null)}
 */
export const getEventId = () => window.mv?.session?.event_id ?? null;

export const getOpenEventId = () => window.mv?.session?.open_event_id ?? null;
