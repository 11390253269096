export const d3rLocalStorage = {
    /**
     * Check if localStorage is available to use
     *
     * @returns {boolean|DOMException} True if available, DOMException if not
     */
    isAvailable: function() {
        try {
            var storage = window['localStorage'],
                x = '__storage_test__'
            storage.setItem(x, x)
            storage.removeItem(x)

            return true
        }
        catch(e) {
            return e instanceof DOMException && (
                // everything except Firefox
                e.code === 22 ||
                // Firefox
                e.code === 1014 ||
                // test name field too, because code might not be present
                // everything except Firefox
                e.name === 'QuotaExceededError' ||
                // Firefox
                e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
                // acknowledge QuotaExceededError only if there's something already stored
                storage.length !== 0;
        }
    },

    /**
     * Set a key with an optional namespace, defaults to D3R.{key}
     *
     * Recommend namespacing based on feature, Checkout.key, Product.key etc.
     *
     * @param {string} key          Key name to store / retrieve data from
     * @param {string} value        Data to be stored
     * @param {string} [namespace]  Namepspace to collect key within, defaults to 'D3R'
     *
     * @returns {boolean}           True if item set
     */
    set: function(key, value, namespace) {
        namespace = (typeof namespace === 'undefined') ? 'D3R' : namespace

        if (this.isAvailable()) {
            localStorage.setItem(namespace + '.' + key, value)
            return (this.exists(key, namespace))
        }

        return false
    },

    /**
     * Get stored variable
     *
     * Return empty string if doesn't exist for ease of filling in forms etc.
     *
     * @param {string} key          Key name to retrieve data from
     * @param {string} [namespace]  Namepspace that contains the key, defaults to 'D3R'
     *
     * @returns {string}           Returns key's value if it exists, empty string if not
     */
    get: function(key, namespace) {
        namespace = (typeof namespace === 'undefined') ? 'D3R' : namespace

        return this.exists(key, namespace) ? localStorage.getItem(namespace + '.' + key) : ''
    },

    /**
     * Delete stored variable
     *
     * @param {string} key          Key name to target for deletion
     * @param {string} [namespace]  Namepspace that contains the key to delete, defaults to 'D3R'
     *
     * @returns {boolean}           True if the key existed
     */
    delete: function(key, namespace) {
        namespace = (typeof namespace === 'undefined') ? 'D3R' : namespace

        var exists = this.exists(key, namespace)
        localStorage.removeItem(namespace + '.' + key)
        return exists
    },

    /**
     * Checks if key exists
     *
     * @param {string} key          Key name to retrieve data from
     * @param {string} [namespace]  Namepspace that contains the key, defaults to 'D3R'
     *
     * @returns {boolean}           True if the key exists
     */
    exists: function(key, namespace) {
        namespace = (typeof namespace === 'undefined') ? 'D3R' : namespace

        if (this.isAvailable()) {
            var target = localStorage.getItem(namespace + '.' + key)
            return (target === null) ? false : true
        }

        return false
    },
};
