window.dataLayer = window.dataLayer || [];

/**
 * Push an event to the data layer
 *
 * @param {object} event
 * @returns {void}
 */
export const dataLayerPush = (event) => { window.dataLayer.push(event); };

/**
 * Push a GAEvent to the data layer
 *
 * @param {string} category
 * @param {string} action
 * @param {string} label
 * @param {any} value
 * @returns {void}
 */
export const pushGAEvent = (category, action, label, value) => dataLayerPush({
  event: 'GAEvent',
  eventCategory: category,
  eventAction: action,
  eventLabel: label,
  eventValue: value,
});

/**
 * Track an event before navigating to a given URI
 *
 * @param {string} uri
 * @param {object} event
 * @returns {void}
 */
export const trackNavigation = (uri, event) => {
  if ('google_tag_manager' in window) {
    let timeoutId;
    event.eventCallback = function () {
      clearTimeout(timeoutId);
      window.location.assign(uri);
    };;
    event.eventTimeout = 2000;

    // If GTM gets in a real mess, step in.
    timeoutId = setTimeout(event.eventCallback, 2500);
  }

  dataLayerPush(event);

  if (!event.eventCallback) {
    window.location.assign(uri);
  }
};
