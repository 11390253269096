import { getEventId } from './offers';

/**
 * Get the currency currently being used by the customer.
 *
 * This is made available by an inline script included in the frame when the
 * page is rendered.
 *
 * @returns {string}
 */
export const getCurrency = () => window.mv?.session?.currency ?? 'GBP';

/**
 * Get the currency currently being used by the customer.
 *
 * This is made available by an inline script included in the frame when the
 * page is rendered.
 *
 * @returns {string}
 */
export const getPriceCatalogue = () => window.mv?.session?.price_catalogue ?? '0';

/**
  * Get the active price field based on the currency and catalogue
  * being used by the customer, and if the customer is in an event
 *
 * This is made available by an inline script included in the frame when the
 * page is rendered.
 *
 * @returns {string}
 */
export const getPriceField = () => `${getEventId() ? 'event_' : '' }price_${getCurrency().toLowerCase()}_${getPriceCatalogue()}`;

/**
 * Get relevant prices for the customer's locale and currency.
 *
 * @param {{locale: string, currency: string, prices: object }} param0
 * @returns {({ now: number, was: number, currency: string })}
 */
export const pricesForCustomer = ({ locale, currency, prices }) => {
  // TODO implement this properly.
  return Object.assign({ currency }, prices.fixed.GBP);
}

export const convertPriceForReporting = (priceInLocalCurrency) => {
    let reportingRate = window.mv?.session?.reportingRate ?? "1.00";
    return (priceInLocalCurrency * reportingRate).toFixed(2);
};

/**
 * Format a price for a given locale and currency.
 *
 * @param {Number} price
 * @param {string} locale
 * @param {string} currency
 * @returns {string}
 */
export const formatPrice = ({ price, locale, currency }) => {
  if (isNaN(price)) {
    return '';
  }

  if (price.toFixed(2) % 1 == 0) {
    return new Intl.NumberFormat('en', { style: 'currency', currency, minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(price);
  }

  return new Intl.NumberFormat('en', { style: 'currency', currency }).format(price);
}
